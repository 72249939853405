<template>
  <div key="calendarTitle">
    <v-card elevation="2" outlined shaped tile class="mb-2">
      <v-card-title>{{ calendarTitle }}</v-card-title>

      <v-dialog v-if="selected" scrollable v-model="showEdit" max-width="700px">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ selected.title }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.name"
                      v-bind:label="`Nombre`"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                    class="text-right"
                    v-if="editedIndex !== -1 && editedIndex"
                  >
                    <span
                      class="border border-dark blue darken-2 rounded-lg py-2 px-3 white--text text-body-1"
                      v-if="
                        isTaskOnTime(
                          selected.status,
                          selected.completedDate,
                          selected.task_endDate
                        )
                      "
                      >A TIEMPO</span
                    >
                    <span
                      class="border border-dark red darken-2 rounded-lg py-2 px-3 white--text text-body-1"
                      v-else
                      >CON RETRASO</span
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      v-model="selected.description"
                      clear-icon="mdi-close-circle"
                      label="Descripción"
                      row-height="15"
                      readonly
                      rows="5"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <!-- <v-row v-if="selected?.static">
                  <v-col cols="12" sm="12" md="12" class="text-center">
                    <v-file-input
                      show-size
                      v-bind:label="`${$t('Upload')} ${$t('PDF File')}`"
                      @change="onChangePDFFile"
                      v-model="selected.static.file"
                      accept=".pdf"
                      required
                      :rules="[fileRules]"
                    >
                      <template v-if="selected.static.static" #label>
                        {{ selected.static.static.file }}
                      </template>
                    </v-file-input>
                    <v-btn
                      v-if="selected.static.static"
                      color="blue darken-1"
                      class="mb-2"
                      @click="downloadPDF(selected.static.static)"
                    >
                      <b-icon icon="download" aria-hidden="true"></b-icon>
                      {{ `${$t("Download")} ${$t("PDF File")}` }}
                    </v-btn>
                  </v-col>
                </v-row> -->
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.username"
                      v-bind:label="`Responsable`"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.brand"
                      v-bind:label="`Marca`"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.project"
                      v-bind:label="`Proyecto`"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.dependency"
                      v-bind:label="`Dependencia`"
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.type"
                      v-bind:label="`Tipo tarea`"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                    v-if="selected.type === 'Recurrente'"
                  >
                    <v-text-field
                      :value="getRecurrenceDescription(selected.recurrence)"
                      v-bind:label="`Recurrencia`"
                      filled
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="selected.type === 'Recurrente'">
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.recurrence_startDate"
                      v-bind:label="$t('brands.start_date')"
                      prepend-icon="mdi-calendar"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.recurrence_endDate"
                      v-bind:label="$t('brands.end_date')"
                      prepend-icon="mdi-calendar"
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="24" sm="12" md="6">
                    <v-text-field
                      v-model="selected.priority"
                      v-bind:label="`Prioridad`"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="24" sm="12" md="6">
                    <v-text-field
                      v-model="selected.status"
                      v-bind:label="`Estado`"
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="4">
                    <v-text-field
                      v-model="selected.task_startDate"
                      v-bind:label="$t('brands.start_date')"
                      prepend-icon="mdi-calendar"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <v-text-field
                      v-model="selected.task_endDate"
                      v-bind:label="$t('brands.end_date')"
                      prepend-icon="mdi-calendar"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <v-text-field
                      v-model="selected.hour"
                      v-bind:label="`Hora`"
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeEditDialog">
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <ve-table
              ref="tableRef"
              :id="tableName"
              fixed-header
              max-height="calc(100vh - 330px)"
              :columns="columns"
              :table-data="tasks"
              :border-around="true"
              :border-x="true"
              :border-y="true"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
              :scroll-width="1500"
            />
            <div v-show="showEmpty" class="empty-data">Datos vacíos.</div>
            <div class="table-pagination mt-2 text-right">
              <ve-pagination
                :total="totaltasks"
                :page-index="pageIndex"
                :page-size="pageSize"
                :page-size-option="pageOptions"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import moment from "moment";
import { logInfo, logError } from "@/utils";
import _ from "lodash";

export default {
  name: "CalendarTasks",
  props: ["calendarTitle", "isToday", "tableName"],
  data: function() {
    return {
      filterable_col_list: [
        "name",
        "priority",
        "hour",
        "brandId",
        "projectId",
        "type",
        "responsibleId",
        "status",
        "task_endDate"
      ],
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          let className = "";
          if (this.filterable_col_list.includes(column.field)) {
            className += "filterable-column " + column.field;
          }
          if (rowIndex === 0) {
            className += " table-header-cell-class";
          }
          return className;
        },
        bodyCellClass: ({ row, column, rowIndex }) => {
          let className = "";
          let today = moment(new Date())
            .local()
            .format("YYYY-MM-DD");
          if (this.getFromStatusAndEndDate(row) === "Completed")
            className += "table-body-cell-completed";
          if (this.getFromStatusAndEndDate(row) === "Pending")
            className += "table-body-cell-pending";
          if (this.getFromStatusAndEndDate(row) === "Delayed")
            className += "table-body-cell-delayed";
          if (this.getFromStatusAndEndDate(row) === "Overdue")
            className += "table-body-cell-overdue";
          return className;
        }
      },
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: params => {
          this.sortChange(params);
        }
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      filterCriteria: {
        status: {
          pending: "Pending",
          delayed: "Delayed"
        },
        name: {},
        brandId: {},
        projectId: {},
        responsibleId: {},
        type: {},
        priority: {},
        description: {},
        task_endDate: {}
      },
      showEmpty: false,
      select_task_startDate_menu: false,
      select_task_endDate_menu: false,

      select_recurrence_startDate_menu: false,
      select_recurrence_endDate_menu: false,

      totaltasks: 0,
      tasks: [],
      filtered_brands: null,
      filtered_projects: null,

      valid: true,
      requiredRules: [v => !!v || "Required"],
      selected: null,
      editedIndex: -1,
      deleteItemId: -1,
      showEdit: false,
      dialogDelete: false,
      WORKINGDAYS_ITEMS: [
        { text: "Domingo", value: "Sun" },
        { text: "Lunes", value: "Mon" },
        { text: "Martes", value: "Tue" },
        { text: "Miercoles", value: "Wed" },
        { text: "Jueves", value: "Thu" },
        { text: "Viernes", value: "Fri" },
        { text: "Sabado", value: "Sat" }
      ],
      FREQ_ITEMS: [
        { text: "días", value: "days" },
        { text: "semanas", value: "weeks" },
        { text: "meses", value: "months" },
        { text: "años", value: "years" }
      ]
    };
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },
  computed: {
    formTitle() {
      return "Mis Tareas";
    },
    columns() {
      return [
        {
          key: "priority",
          title: "Prioridad",
          field: "priority",
          align: "left",
          width: 80,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <v-checkbox
                    v-model={this.filterCriteria["priority"]["high"]}
                    label="Alta"
                    value="Alta"
                  ></v-checkbox>
                  <v-checkbox
                    v-model={this.filterCriteria["priority"]["medium"]}
                    label="Media"
                    value="Media"
                  ></v-checkbox>
                  <v-checkbox
                    v-model={this.filterCriteria["priority"]["low"]}
                    label="Baja"
                    value="Baja"
                  ></v-checkbox>
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "priority")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "task_endDate",
          title: "Fecha límite",
          field: "task_endDate",
          align: "left",
          width: 150,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <span>
                {row.task_endDate} {row.hour}
              </span>
            );
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <input
                    type="date"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["task_endDate"]["from"]}
                    value={this.filterCriteria["task_endDate"]["from"]}
                    placeholder="From - "
                  />
                  <br />
                  <input
                    type="date"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["task_endDate"]["to"]}
                    value={this.filterCriteria["task_endDate"]["to"]}
                    placeholder="To - "
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() =>
                        this.searchCancel(closeFn, "task_endDate")
                      }
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "name",
          title: "Tarea",
          field: "name",
          align: "left",
          width: 100,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["name"]}
                    apiUrl={`tasks/getfilterlist`}
                    columnName={`name`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["name"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "name")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        // {
        //   key: "hour",
        //   title: "Hora",
        //   field: "hour",
        //   align: "left",
        //   width: 70,
        //   sortBy: ""
        // },
        {
          key: "brandId",
          title: "Marca",
          field: "brandId",
          align: "left",
          width: 110,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row.brand?.name}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["brandId"]}
                    apiUrl={`tasks/getfilterlist`}
                    columnName={`brandId`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["brandId"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "brandId")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "projectId",
          title: "Proyecto",
          field: "projectId",
          align: "left",
          width: 110,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row?.project?.name}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["projectId"]}
                    apiUrl={`tasks/getfilterlist`}
                    columnName={`projectId`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["projectId"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "projectId")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "type",
          title: "Tipo Tarea",
          field: "type",
          align: "left",
          width: 120,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <v-checkbox
                    v-model={this.filterCriteria["type"]["puntual"]}
                    label="Puntual"
                    value="Puntual"
                  ></v-checkbox>
                  <v-checkbox
                    v-model={this.filterCriteria["type"]["recurrente"]}
                    label="Recurrente"
                    value="Recurrente"
                  ></v-checkbox>
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "type")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "status",
          title: "Estado",
          field: "status",
          align: "left",
          width: 150,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <span>
                {this.getFromStatusAndEndDate(row) === "Completed"
                  ? "A tiempo - Finalizada"
                  : this.getFromStatusAndEndDate(row) === "Pending"
                  ? "A tiempo - Pendiente"
                  : this.getFromStatusAndEndDate(row) === "Delayed"
                  ? "Con retraso - Pendiente"
                  : "Con retraso - Finalizada"}
              </span>
            );
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <v-checkbox
                    v-model={this.filterCriteria["status"]["completed"]}
                    label="A tiempo - Finalizada"
                    value="Completed"
                  ></v-checkbox>
                  <v-checkbox
                    v-model={this.filterCriteria["status"]["pending"]}
                    label="A tiempo - Pendiente"
                    value="Pending"
                  ></v-checkbox>
                  <v-checkbox
                    v-model={this.filterCriteria["status"]["delayed"]}
                    label="Con retraso - Pendiente"
                    value="Delayed"
                  ></v-checkbox>
                  <v-checkbox
                    v-model={this.filterCriteria["status"]["overdue"]}
                    label="Con retraso - Finalizada"
                    value="Overdue"
                  ></v-checkbox>
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "status")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "responsibleId",
          title: "Responsable",
          field: "responsibleId",
          align: "left",
          width: 110,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <span>
                {row.tasksResponsible &&
                  row.tasksResponsible?.name +
                    " " +
                    row.tasksResponsible?.surname}
              </span>
            );
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["responsibleId"]}
                    apiUrl={`tasks/getfilterlist`}
                    columnName={`responsibleId`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["responsibleId"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() =>
                        this.searchCancel(closeFn, "responsibleId")
                      }
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "actions",
          title: "Ir",
          field: "actions",
          align: "center",
          fixed: "right",
          width: 50,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div>
                <v-icon
                  medium
                  color="primary"
                  class="mr-2"
                  on-click={() => this.onItemEdit(row)}
                >
                  mdi-plus
                </v-icon>
              </div>
            );
          }
        }
      ];
    }
  },
  methods: {
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector(`#${this.tableName}`),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },

    close() {
      this.loadingInstance.close();
    },

    createSelectedModel(item) {
      let model = {
        id: _.get(item, "id"),
        name: _.get(item, "name"),
        projectId: _.get(item, "projectId"),
        username:
          _.get(item?.tasksResponsible, "username") +
          " " +
          _.get(item?.tasksResponsible, "surname"),
        brand: _.get(item?.brand, "name"),
        project: _.get(item?.project, "name"),
        brandId: _.get(item, "brandId"),
        priority: _.get(item, "priority"),
        task_startDate: _.get(item, "task_startDate"),
        task_endDate: _.get(item, "task_endDate"),
        recurrence_startDate: _.get(item, "recurrence_startDate"),
        recurrence_endDate: _.get(item, "recurrence_endDate"),
        hour: _.get(item, "hour"),
        type: _.get(item, "type"),
        assignedBy: _.get(item?.tasksAssignedBy, "name"),
        status: _.get(item, "status"),
        assignmentDate: _.get(item, "assignmentDate"),
        dependencyId: _.get(item, "dependencyId"),
        description: _.get(item, "description"),
        recurrence: _.get(item, "recurrence"),
        responsibleId: _.get(item, "responsibleId"),
        completedDate: _.get(item, "completedDate")
      };
      model["title"] = `${model.name} ${model.brand}`;
      return model;
    },

    onItemEdit(item) {
      this.selected = this.createSelectedModel(item);
      this.editedIndex = this.selected.id;
      this.showEdit = true;
    },

    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },

    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },

    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },

    closeEditDialog() {
      this.showEdit = false;
      this.$nextTick(() => {
        this.selected = null;
        this.editedIndex = -1;
      });
    },

    isTaskOnTime(status, completedDate, endDate) {
      let today = moment(new Date())
        .local()
        .format("YYYY-MM-DD");
      if (status === "Finalizada") return completedDate <= endDate;
      return today <= endDate;
    },

    getQueryParams() {
      let query_params_string = "";
      let query_params = [];
      query_params.push("page=" + this.pageIndex);
      query_params.push("itemsPerPage=" + this.pageSize);
      let local_date = moment(new Date())
        .local()
        .format("YYYY-MM-DD");
      if (this.isToday) {
        query_params.push("daily=" + local_date);
      } else {
        let sevenDaysLater = new Date();
        sevenDaysLater.setDate(new Date().getDate() + 7);
        sevenDaysLater = moment(sevenDaysLater)
          .local()
          .format("YYYY-MM-DD");
        query_params.push("weekly_st=" + local_date);
        query_params.push("weekly_en=" + sevenDaysLater);
      }

      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            query_params.push("sortby=" + key);
            query_params.push(
              "sortdesc=" + (value === "desc" ? "true" : "false")
            );
          }
        }
      }

      let filterModel = [];
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        if (value !== "" && value !== null) {
          if (Object.keys(value).length > 0) {
            filterModel.push({
              columnField: key,
              filterValue: value
            });
          }
        }
      }
      let filter_columns = document.querySelectorAll(
        `#${this.tableName} .filterable-column`
      );
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      if (filterModel.length > 0) {
        filterModel.map(filter_item => {
          for (let i = 0; i < filter_columns.length; i++) {
            if (filter_columns[i].classList.contains(filter_item.columnField)) {
              filter_columns[i].classList.add("filter_active");
            }
          }
          return filter_item;
        });
      }

      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      return query_params_string;
    },

    formatDateToISO(date) {
      return date.toISOString().split("T")[0];
    },

    isSameOrBetween(tempDate) {
      let date = tempDate;
      // let local_date = this.formatDateToISO(new Date());
      let local_date = moment(new Date())
        .local()
        .format("YYYY-MM-DD");
      if (this.isToday) {
        return date <= local_date;
      } else {
        let sevenDaysLater = new Date();
        sevenDaysLater.setDate(sevenDaysLater.getDate() + 7);
        sevenDaysLater = this.formatDateToISO(sevenDaysLater);
        return local_date < date && date < sevenDaysLater;
      }
    },

    getTextFromFreq(freq) {
      const result = this.FREQ_ITEMS.filter(el => el.value === freq);
      return result[0].text;
    },

    getTextFromWorkingDays(workingDays) {
      let data_list = [];
      this.WORKINGDAYS_ITEMS.forEach(el => {
        if (workingDays.includes(el.value)) data_list.push(el.text);
      });
      return data_list;
    },

    getRecurrenceDescription(recurrence) {
      if (!recurrence) return "";

      let description = "Cada ";
      const frequencyText = this.getTextFromFreq(recurrence.freq);

      if (recurrence.interval === "1") {
        const sliceIndex = recurrence.freq === "months" ? -2 : -1;
        description += frequencyText.slice(0, sliceIndex);
      } else {
        description += `${recurrence.interval} ${frequencyText}`;
      }

      if (recurrence.freq === "days" && recurrence.isWeekend === "1") {
        description += " durante los días laborables";
      } else if (
        recurrence.freq === "weeks" &&
        recurrence.workingDays?.length
      ) {
        const workingDaysText = this.getTextFromWorkingDays(
          recurrence.workingDays
        );
        description += ` en ${workingDaysText.join(", ")}`;
      }

      return description;
    },

    getDateFromRecurrence(date, recurrence) {
      if (!recurrence) return null;
      let currentDate = new Date(date);
      let interval = parseInt(recurrence.interval);
      if (recurrence.freq === "days") {
        currentDate.setDate(currentDate.getDate() + interval);
        if (recurrence.isWeekend === "1") {
          let isValid = 1;
          while (isValid) {
            let day = currentDate.getUTCDay();
            if (day !== 0 && day !== 6) break;
            currentDate.setDate(currentDate.getDate() + interval);
          }
        }
      } else if (recurrence.freq === "weeks") {
        if (recurrence?.workingDays && recurrence.workingDays.length) {
          let isValid = 1;
          while (isValid) {
            currentDate.setDate(currentDate.getDate() + 1);
            let day = currentDate.getUTCDay();
            if (!day) {
              currentDate.setDate(currentDate.getDate() + 7 * interval - 7);
            }
            let item = this.WORKINGDAYS_ITEMS[day];
            if (recurrence.workingDays.includes(item.value)) {
              break;
            }
          }
        } else {
          currentDate.setDate(currentDate.getDate() + 7 * interval);
        }
      } else if (recurrence.freq === "months") {
        currentDate.setMonth(currentDate.getMonth() + interval);
      } else if (recurrence.freq === "years") {
        currentDate.setFullYear(currentDate.getFullYear() + interval);
      }
      return currentDate.toISOString().split("T")[0];
    },

    getFromStatusAndEndDate(temp) {
      let today = moment(new Date())
        .local()
        .format("YYYY-MM-DD");
      if (
        temp.status === "Finalizada" &&
        temp.completedDate <= temp.task_endDate
      )
        return "Completed";
      if (temp.status === "Pendiente" && today <= temp.task_endDate)
        return "Pending";
      if (temp.status === "Pendiente" && today > temp.task_endDate)
        return "Delayed";
      if (
        temp.status === "Finalizada" &&
        temp.completedDate > temp.task_endDate
      )
        return "Overdue";
    },

    async setCalendarItems(data) {
      let data_list = [];
      for (let el of data) {
        if (el.type === "Recurrente") {
          el = { ...el, recurrence: JSON.parse(el.recurrence) };
          let tempStartDate = el.recurrence_startDate;
          let tempEndDate = el.recurrence_endDate;
          let limit = 3000;
          let taskTrackingCur = 0;
          for (let i = 0; i < limit; i++) {
            if (tempEndDate < tempStartDate) break;
            let temp = el;
            if (
              taskTrackingCur < el.taskTrackingsTaskId.length &&
              el.taskTrackingsTaskId[taskTrackingCur].date === tempStartDate
            ) {
              temp = el.taskTrackingsTaskId[taskTrackingCur];
              temp = {
                ...temp,
                recurrence: JSON.parse(temp.recurrence),
                tasksResponsible: temp.taskTrackingsResponsible
              };
              taskTrackingCur++;
            }
            temp = { ...temp, task_endDate: tempStartDate };
            if (
              this.isSameOrBetween(tempStartDate) &&
              !temp.isRemoved &&
              this.isDateWithinRecurrence(tempStartDate, el.recurrence)
            ) {
              if (
                Object.values(this.filterCriteria["status"]).includes(
                  this.getFromStatusAndEndDate(temp)
                )
              )
                data_list.push(temp);
            }
            tempStartDate = this.getDateFromRecurrence(
              tempStartDate,
              el.recurrence
            );
          }
        } else {
          if (
            this.isSameOrBetween(el.task_endDate) &&
            Object.values(this.filterCriteria["status"]).includes(
              this.getFromStatusAndEndDate(el)
            )
          )
            data_list.push(el);
        }
      }
      this.tasks = data_list;
      this.sortTasks();
      this.totaltasks = data_list.length;
    },

    sortTasks() {
      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            let isIncreasing = value === "desc" ? 1 : -1;
            this.tasks.sort((a, b) => {
              if (key === "status") {
                if (
                  this.getFromStatusAndEndDate(a) <
                  this.getFromStatusAndEndDate(b)
                )
                  return isIncreasing;
                return -isIncreasing;
              }
              if (a[key] < b[key]) {
                return isIncreasing;
              }
              return -isIncreasing;
            });
          }
        }
      }
    },

    isDateWithinRecurrence(date, recurrence) {
      if (!recurrence) return true;

      const dayOfWeek = new Date(date).getUTCDay();
      let isValid = true;

      if (recurrence.freq === "days" && recurrence.isWeekend === "1") {
        if (dayOfWeek === 0 || dayOfWeek === 6) isValid = false;
      }
      if (recurrence.freq === "weeks" && recurrence.workingDays?.length) {
        const workingDay = this.WORKINGDAYS_ITEMS[dayOfWeek];
        if (!recurrence.workingDays.includes(workingDay.value)) isValid = false;
      }

      return isValid;
    },

    async getDataFromApi() {
      this.show();
      let query_params_string = this.getQueryParams();
      let fetch_url = "tasks";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      const resp = await ApiService.get(fetch_url);
      await this.setCalendarItems(resp.data);
      this.filtered_brands = resp.brand_list;
      this.filtered_projects = resp.project_list;
      if (this.tasks.length > 0) {
        this.showEmpty = false;
      } else {
        this.showEmpty = true;
      }
      this.close();
    },

    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },

    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      this.filterCriteria[cancelFilterKey] = {};
      this.getDataFromApi();
    },

    init() {
      this.getDataFromApi();
    }
  },
  async mounted() {
    this.init();
  }
};
</script>

<style>
.table-body-cell-completed {
  background-color: rgb(51, 182, 121) !important;
}
.table-body-cell-pending {
  background-color: #fff !important;
}
.table-body-cell-overdue {
  background-color: orange !important;
}
.table-body-cell-delayed {
  background-color: rgb(213, 0, 0) !important;
}
.table-header-cell-class {
  background-color: #004375ed !important;
}
</style>
