<template>
  <div>
    <Calendar></Calendar>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12">
        <CalendarTasks
          :calendarTitle="`Tareas Hoy`"
          :isToday="true"
          :tableName="`todayTasks`"
        ></CalendarTasks>
      </v-col>

      <v-col cols="12" sm="12" md="12" lg="12">
        <CalendarTasks
          :calendarTitle="`Tareas Próximos dias`"
          :tableName="`nextTasks`"
        ></CalendarTasks>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Calendar from "./Calendar.vue";
import CalendarTasks from "./CalendarTasks.vue";

export default {
  name: "VisionCalendar",
  components: {
    Calendar,
    CalendarTasks
  }
};
</script>

<style></style>
